/*------------------------------------*\
    # COMPONENT OVERRIDES
\*------------------------------------*/

/**
 * Add local component overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

/* Temp CSS fixes removed from static block */

/* Temp fix for layout caching issue */
.cms-page-view .page-layout--two-col .page-layout__item--primary,
.cms-home .page-layout--two-col .page-layout__item--primary {
    width: 100% !important;
}
/* Fix for banner sale issue. */
@media (max-width: 960px) {
    .catalog-product-view .product-info .sale-banner-exists {
        position: relative;
        top: 0 !important;
    }
    .catalog-product-view .product-img-inc-banner {
        margin-top: -100px;
    }
}
/* End Fix */
/* Fix for specific page alignment issues */
.cms-fusion-rewards .full-site-banner .wrapper--gutter,
.cms-privacy-policy .wrapper--gutter,
.contact-index-index .wrapper--gutter {
    padding-left: 1.875rem !important;
    padding-right: 1.875rem !important;
    max-width: 82.5rem !important;
    padding-top: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
/* End Gutter Fix */

/* End temp fix */
.campaign-site-banner {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 960px) {
    .home-main-panels {
        margin-top: 0px !important;
    }
}

/* Fix for BIS button to hide initially */
.product-notify-me-wrapper .hidden-notify {
    display: none;
}

.product-info__main .product-addto-links .towishlist span {
    top: -6px;
}
/* Fix for sale badge */
.wrapper--gutter {
    position: relative;
}
@media (max-width: 750px) {
    .product-info .badge-container {
        margin-left: 0px !important;
    }
}
@media (max-width: 960px) {
    .product-info .badge-container {
        top: 10px !important;
    }
}
