.navigation-bar__group--account,
.site-header__mobile-links {
  .wishlist {
    .counter {
      color: $color-blue;
      border: 2px solid $color-blue;
    }
  }
}

/* Fix for save icon broken by react */
.product-list__item .product-tile .product-tile__actions--secondary .product-tile__action--add-to-wishlist:before {
  font-family: Material Icons !important;
}