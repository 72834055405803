/*------------------------------------*\
    # BASE OVERRIDES
\*------------------------------------*/

/**
 * Add local base style overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

@mixin functional-font-base {
  font-family: $font-family-base-functional;
}

/* Fix for save icon broken by react */
.product-list__item .product-tile .product-tile__actions--secondary .product-tile__action--add-to-wishlist:before {
  font-family: Material Icons !important;
}

/* MMI-712 - Icon fix for iOS */
@media (max-width: 960px) {
  .primary-navigation-mobile li.stockistsMenuItem a:after {
    background: url(/media/wysiwyg/Mathers/iOS-icons/location.png) no-repeat;
    width: 24px;
    height: 24px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    display: block;
    right: 18px;
  }
  .toolbar__sorter .sorter-container .sorter__label:after {
    font-family: "FontAwesome" !important;
    transform: rotate(90deg);
    content: "\f105" !important;
    right: 10px !important;
    font-size: 23px !important;
    top: 5px !important;
  }
  .refine-filters__apply:after {
    font-family: "FontAwesome" !important;
    content: "\f00c" !important;
    font-size: 16px !important;
    margin-top: 3px !important;
  }
  .refine-filters__cancel:after {
    font-family: "FontAwesome" !important;
    content: "\f00d" !important;
    font-size: 16px !important;
    margin-left: 10px !important;
    margin-top: 3px !important;
  }
  .site-header__mobile-top .minisearch .search-close-button::before {
    font-size: 23px !important;
    background: url(/media/wysiwyg/Mathers/iOS-icons/cross.png) no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    margin-right: 10px !important;
    display: inline-block;
  }
  .popup_wrapper .popup-close button:before,
  .modal-popup:not(.confirm) .action-close:after,
  .popup_content .popup-close button:before {
    background: url(/media/wysiwyg/Mathers/iOS-icons/cross.png) no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    font-size: 18px !important;
    display: inline-block;
  }
  .accordion--enabled .accordion__header:after {
    font-family: "FontAwesome" !important;
    content: "\f067" !important;
    font-size: 16px !important;
  }
  .accordion--enabled .accordion__panel--open .accordion__header:after {
    font-family: "FontAwesome" !important;
    content: "\f068" !important;
    font-size: 16px !important;
  }
  .wishlist-index-index .product-tile__action--delete:before {
    background: url(/media/wysiwyg/Mathers/iOS-icons/cross.png) no-repeat;
    width: 12px;
    height: 10px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    line-height: 20px !important;
    margin-right: 3px !important;
  }
  .wishlist-index-index .product-tile__action--edit:before {
    line-height: 20px !important;
    margin-right: 3px !important;
    background: url(/media/wysiwyg/Mathers/iOS-icons/edit.png) no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    outline: 0;
    content: "" !important;
  }
  #shopping-cart-table .cart__action--update::before {
    font-family: "FontAwesome" !important;
    content: "\f01e" !important;
    font-size: 18px !important;
  }

  .cart-summary .icon-local_phone:before {
    font-family: "FontAwesome" !important;
    content: "\f095" !important;
    font-size: 24px !important;
    margin-left: 4px !important;
  }
  .cart-page-header .cart__list--action::before {
    font-size: 20px !important;
    margin-right: 5px !important;
    font-family: "FontAwesome" !important;
    transform: rotate(180deg) !important;
    content: "\f105" !important;
  }
  .one-page-checkout__progress-bar .one-page-checkout__progress-step::before {
    font-family: "FontAwesome" !important;
    content: "\f141" !important;
    font-size: 22px !important;
  }
  .one-page-checkout__summary .items-in-cart .title::after {
    right: 10px !important;
    font-family: "FontAwesome" !important;
    transform: rotate(90deg) !important;
    content: "\f105" !important;
  }
  .one-page-checkout__summary .items-in-cart.active .title::after {
    right: 10px !important;
    font-family: "FontAwesome" !important;
    transform: rotate(-90deg) !important;
    content: "\f105" !important;
  }
  .stockists-directory-list > div .stockists-directory__item .directory-item .directory-item__phone:before {
    font-family: "FontAwesome" !important;
    content: "\f095" !important;
    margin-right: 6px !important;
  }
  .stockists-directory-list > div .stockists-directory__item .directory-item address:before {
    background: url(/media/wysiwyg/Mathers/iOS-icons/location-black.png) no-repeat;
    content: "" !important;
    display: inline-block;
    background-size: contain;
    width: 24px;
    height: 24px;
    outline: 0;
  }
  .fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store {
    padding-top: 0px !important;
  }
  .fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store:before {
    background: url(/media/wysiwyg/Mathers/iOS-icons/location-green.png) no-repeat;
    content: "" !important;
    display: inline-block;
    background-size: contain;
    width: 26px;
    height: 26px;
    outline: 0;
  }
}
