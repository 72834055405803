/* Styling for the container that holds the sitemap links and contact us block */
 div.footer-sitemap-contact {
   background-color: $color-background-footer-sitemap-contact;
   color: $color-white;
   padding: 0;
  @include media-query(medium-and-up) {
    padding: 40px 70px;
  }
}

.newsletter-footer-block-wrapper .site-footer__subscribe.newsletterSignUp {
  background-color: $color-mathers-mint;
  color: #FFF;
}

.site-footer__submit, .site-footer__submit:hover {
  background-color: #000;
}