.navigation-bar-scrolled {
  .site-header__logo-container {

    img {

      &.scrolled-logo {
        display: block;
        max-height: 25px;
      }

      &.main-logo {
        display: none;
      }
    }
  }

  .search-suggest-icon-wrapper {
    .search-suggest-icon:before {
      color: $color-blue;
      @include icomoon('search');
    }

    .search-suggest-icon:hover, .search-suggest-icon:focus {
      background-color: transparent;
    }
  }

  .account-navigation {
    height: 100%;
  }
}
