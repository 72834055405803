.site-header__banner {
  background: $color-white;
}

.site-header__mobile-top {
  .mobile-header-search-toggle-container {
    .mobile-header-search-toggle {
      &:before {
        color: $color-blue;
      }
    }
  }
}
