.product-tile__actions--secondary .tocompare:before {
  background-image: url(/media/wysiwyg/Mathers/compare-icons/Compare-black1.png);
  background-size: contain;
  width: 25px;
  height: 25px;
  margin: 0;
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  margin-right: 0.25rem;
 }

.product-tile__actions--secondary .tocompare:hover:before {
  background-image: url(/media/wysiwyg/Mathers/compare-icons/Compare1.png); 
}

.product-list__item .product-tile .product-tile__actions--secondary {
  font-size: 14px;
}