.product-tile__badges {
  top: 1.2rem;
  left: 1.2rem;
}

@media (max-width: 960px) {
  .product-tile__badges {
    right: auto;
    top: 1.2rem;
    left: 0.25rem;
  }
}