/*------------------------------------*\
    # BASE OVERRIDES
\*------------------------------------*/

/**
 * Add local base style overrides here, but keep them to a minimum. Where
 * possible you should refactor the base theme's code to use variables, mixins
 * and logic instead.
 **/

.popup-close-button:hover {
  background-color: #fff;
}

.header-sub-strip__list {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Fix for postcode autofill for iPhone */
#co-shipping-form .postcode-autosuggest .postcode-suggestion,
.my-account__group--address-book-address .postcode-autosuggest .postcode-suggestion {
  cursor: pointer;
}

/* Account Order Pager */
.sales-order-history .toolbar__pager {
  display: initial;
}

.page-layout--two-col .page-layout__item--primary {
  width: 100% !important;
}

.page-layout--two-col .page-layout__item--tertiary {
  width: 0% !important;
  display: none;
}
/* MMI-712 - Icon fix for iOS */
@media (max-width: 960px) {
  .search-close-button::before {
    font-size: 23px !important;
    background: url(/media/wysiwyg/Wanted/iOS-icons/cross.png) no-repeat;
    width: 14px;
    height: 14px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    margin-right: 10px !important;
    display: inline-block;
    vertical-align: text-bottom !important;
    margin-bottom: 3px !important;
  }
  .primary-navigation-mobile li.stockistsMenuItem a:after {
    background: url(/media/wysiwyg/Williams/iOS-icons/location.png) no-repeat;
    width: 24px;
    height: 24px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    display: block;
    right: 18px;
  }
  .stockists-directory-list > div .stockists-directory__item .directory-item .directory-item__phone:before {
    font-family: "FontAwesome" !important;
    content: "\f095" !important;
    margin-right: 6px !important;
  }
  .stockists-directory-list > div .stockists-directory__item .directory-item address:before {
    background: url(/media/wysiwyg/Williams/iOS-icons/location-black.png) no-repeat;
    content: "" !important;
    display: inline-block;
    background-size: contain;
    width: 24px;
    height: 24px;
    outline: 0;
  }
  .popup_wrapper .popup-close button:before,
  .modal-popup:not(.confirm) .action-close:after,
  .popup_content .popup-close button:before {
    background: url(/media/wysiwyg/Williams/iOS-icons/cross.png) no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    font-size: 18px !important;
    display: inline-block;
  }
  .accordion--enabled .accordion__header:after {
    font-family: "FontAwesome" !important;
    content: "\f067" !important;
    font-size: 16px !important;
  }
  .accordion--enabled .accordion__panel--open .accordion__header:after {
    font-family: "FontAwesome" !important;
    content: "\f068" !important;
    font-size: 16px !important;
  }
  .fis-enabled .product-info__main .product-info__social .find-in-store .button--find-in-store:before {
    background: url(/media/wysiwyg/Williams/iOS-icons/location-red-2.png) no-repeat;
    content: "" !important;
    display: inline-block;
    background-size: contain;
    width: 26px;
    height: 26px;
    outline: 0;
  }
  .wishlist-index-index .product-tile__action--delete:before {
    background: url(/media/wysiwyg/Williams/iOS-icons/cross.png) no-repeat;
    width: 12px;
    height: 10px;
    background-size: contain;
    outline: 0;
    content: "" !important;
    line-height: 20px !important;
    margin-right: 3px !important;
  }
  .wishlist-index-index .product-tile__action--edit:before {
    line-height: 20px !important;
    margin-right: 3px !important;
    background: url(/media/wysiwyg/Williams/iOS-icons/edit.png) no-repeat;
    width: 12px;
    height: 12px;
    background-size: contain;
    outline: 0;
    content: "" !important;
  }
}
