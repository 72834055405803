.fis-enabled .product-info__main .product-info__social {
  display: flex;
  flex-flow: row wrap;

  > * {
    flex: 1 100%;
  }

  .find-in-store {
    flex: 1 0 0;
    padding: 5px;
    margin: 15px 7px 10px 0;
    height: 40px;
    border: 1px solid $color-blue;

    .button--find-in-store::before {
      margin-right: 10px;
      color: $color-blue;
    }
  }

  .product-addto-links {
    flex: 1 0 0;
    margin: 15px 0 10px 7px;
    height: 40px;
    border: 1px solid $color-blue;

    .towishlist:before {
      margin-right: 10px;
      margin-top: -2px;
      color: $color-blue;
    }

  }
}