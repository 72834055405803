.comparison {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
}

.hidden-print {
        margin-bottom: 0px;
        padding: 10px 25px;
        width: 180px;
}

.compare-actions {
        position: absolute;
        top: 30px;
        right: 30px;

        span {
                font-size: 10px;
        }
        .compare-save {
                border-color: transparent;
                border-style: solid;
                border-width: 1px;
                color: #fff;
                display: none;
                width: 180px;
                text-align: center;
                text-decoration: none;
                margin-bottom: 0px;
                font-size: 0.875rem;
                padding: 10px 25px;
                background-color: #008996;
                text-transform: uppercase;
                text-decoration: none;
                margin-right: 15px;
        }
        .compare-save:before {
                background-image: url(/media/wysiwyg/Mathers/compare-icons/Add-to-Account1.png);
                background-size: contain;
                width: 25px;
                height: 25px;
                margin: 0;
                display: none;
                content: "";
                background-repeat: no-repeat;
                margin-right: 0.25rem;
                vertical-align: middle;
        }
        .hidden-print:before {
                background-image: url(/media/wysiwyg/Mathers/compare-icons/Print1.png);
                background-size: contain;
                width: 25px;
                height: 25px;
                margin: 0;
                display: inline-block;
                content: "";
                background-repeat: no-repeat;
                margin-right: 0.25rem;
                vertical-align: middle;
        }
}

.catalog-product_compare-index {
        .empty {
                text-align: center;
        }
        .modal-popup.confirm .modal-footer .action-accept {
                padding: 10px 25px;
                font-size: 10px;
                width: 120px;
                height: 35px;
                min-width: 120px;
                line-height: 0;
        }
        .modal-popup.confirm .modal-footer .action-dismiss {
                padding: 10px 25px;
                font-size: 10px;
                width: 120px;
                border: 2px solid #008996;
                height: 35px;
                min-width: 120px;
                line-height: 0;
        }
        .modal-inner-wrap {
                background-color: white;
        }
        .modal-popup.confirm .modal-content {
                padding-bottom: 30px;
        }
        .modal-popup.confirm .modal-header .action-close:before {
                font-size: 18px;
                line-height: 0em;
        }
        .modal-popup .action-close {
                padding: 5px;
        }
        .product-delete {
                text-decoration: none;
        }
        .product-delete:before {
                line-height: 0em;
                background-image: url(/media/wysiwyg/Mathers/compare-icons/cross.png);
                background-size: contain;
                width: 15px;
                height: 15px;
                margin: 0;
                display: inline-block;
                content: "";
                background-repeat: no-repeat;
                vertical-align: text-top;
                margin-right: 5px;
        }
        .product-delete span {
                display: none;
        }
}

.col-product {
        width: calc(25% - 35px);
        min-height: 880px;
        position: relative;
        margin: 20px 10px;
}
.col-product:hover {
        opacity: 0.8;
}
.col-product .remove {
        text-align: right;
        width: 100%;
        position: absolute;
}
.compare-product-info {
        min-height: 800px;

        .main-head {
                font-weight: 600;
                margin: 20px 0 10px 0;
                font-size: 15px;
        }
        .sub-head {
                font-weight: 600;
                font-size: 12px;
                flex-basis: 30%;
                padding-bottom: 5px;
        }
        .sub-text {
                font-size: 12px;
                flex-basis: 68%;
                padding-bottom: 5px;
        }
        .compare-products-attribute {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
        }
        .product-item-name {
                padding-top: 5px;
        }
        .product-item-name a {
                text-transform: capitalize;
                text-decoration: none;
        }
        .view-more {
                font-size: 12px;
                vertical-align: middle;
                margin: 10px 0;
        }
        .view-more:after {
                background-image: url(/media/wysiwyg/Mathers/compare-icons/more.png);
                background-size: contain;
                width: 13px;
                height: 13px;
                margin: 0;
                display: inline-block;
                content: "";
                background-repeat: no-repeat;
                margin-left: 5px;
                margin-bottom: 3px;
                vertical-align: middle;
        }
}

.compare-product-item-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        bottom: -15px;

        .actions-primary {
                flex-basis: 85%;
        }
        .actions-secondary {
                flex-basis: 12%;
        }
}

.compare-product-item-actions .tocart,
.compare-product-item-actions .towishlist {
        text-transform: uppercase;
        font-size: 12px;
        width: 100%;
        height: 35px;
        background-color: #008996;
        display: block;
        text-decoration: none;
        text-align: center;
        font-family: azo-sans-web, sans-serif;
        color: #ffffff;
        line-height: 1px;
}
.compare-product-item-actions {
        .towishlist {
                background-color: white !important;
                border: 1px solid #008996;
        }
        .towishlist span:before {
                content: "";
                font-family: "icomoon";
                font-size: 25px;
                position: relative;
                top: 4px;
                top: 16px;
                color: #008996;
        }
        .tocart span:before {
                content: "";
                font-family: "icomoon";
                font-size: 25px;
                position: relative;
                top: 4px;
        }
}

.block-compare .counter {
        display: none;
}
.block-compare .block-content {
        padding-top: 25px;
}
.block-content #compare-items {
        list-style-type: none;
        margin-left: 0;
        margin-bottom: 0;
}
#compare-items .product-item {
        border-top: 2px solid black;
        padding: 12px 0;
        font-size: 12px;
        line-height: 1;
        display: flex;
        justify-content: space-between;
}
.block-content {
        #table-head {
                display: flex;
                justify-content: flex-start;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 12px;
        }

        .name-product {
                margin-left: 10px;
        }
        .actions-product a,
        .actions-toolbar a {
                border-radius: 15px;
                border-style: solid;
                border-width: 0;
                color: #fff;
                width: 100px;
                text-align: center;
                text-decoration: none;
                margin-bottom: 0px;
                font-size: 11px;
                letter-spacing: 1px;
                padding: 5px 25px;
                background-color: #008996;
                text-transform: uppercase;
                text-decoration: none;
                margin-right: 15px !important;
                margin: auto;
        }
        .actions-toolbar {
                display: flex;
                justify-content: center;
                padding-top: 25px;
                border-top: 2px solid black;
        }
}

.navigation-bar__group--account .compare > a:before,
.site-header__mobile-links .compare > a:before {
        background-image: url(/media/wysiwyg/Mathers/compare-icons/compare-white1.png);
        background-size: contain;
        width: 28px;
        height: 28px;
        margin: 0;
        display: inline-block;
        content: "";
        background-repeat: no-repeat;
        margin: auto;
        display: block;
        top: -1px;
        position: relative;
}
.navigation-bar-scrolled .navigation-bar__group .compare > a:before {
        background-image: url(/media/wysiwyg/Mathers/compare-icons/Compare-black1.png);
}

.navigation-bar__group--account .compare .nav-compare-text {
        top: 4px;
        position: relative;
}

.site-header__mobile-links .compare .nav-compare-text {
        display: none;
}

@media (max-width: 960px) {
        .comparison {
                justify-content: space-between;
        }
        .col-product {
                width: calc(50% - 25px);
                min-height: 500px !important;
        }
        .compare-actions .print {
                display: none;
        }
        .compare-save {
                width: 130px;
                padding: 5px;
                margin-right: 0;
                display: none;
        }
        .compare-actions .compare-save:before {
                width: 15px;
                height: 15px;
                display: none;
        }
        .product-item-photo .product-image-photo {
                max-width: 200px !important;
        }
        .compare-product-item-actions .actions-primary {
                flex-basis: 75%;
        }
        .compare-product-item-actions .actions-secondary {
                flex-basis: 20%;
        }
}

#compare-items .product-item-name .product-item-link {
        text-decoration: none;
        text-transform: capitalize;
}
.compare-product-info .product-image-photo {
        width: 160px;
}

@media (max-width: 750px) {
        .compare-product-info .product-image-photo {
                width: 140px;
        }
        .compare-product-info .sub-head,
        .compare-product-info .sub-text {
                font-size: 11px;
        }
}
@media (max-width: 400px) {
        .compare-product-info .sub-head,
        .compare-product-info .sub-text {
                font-size: 10px !important;
        }
}

.block-content-sidebar {
        #remove-compare-item :before {
                background-image: url(/media/wysiwyg/Mathers/compare-icons/clear-circle-black.png);
                background-size: contain;
                width: 15px;
                height: 15px;
                margin: 0;
                display: inline-block;
                content: "";
                background-repeat: no-repeat;
                vertical-align: text-top;
                margin-right: 5px;
        }
        .actions-toolbar {
                a {
                        border: 2px solid #008996;
                }
                #compare-clear-all :before {
                        background-image: url(/media/wysiwyg/Mathers/compare-icons/Clear1.png);
                        background-size: contain;
                        width: 18px;
                        height: 15px;
                        margin: 0;
                        display: inline-block;
                        content: "";
                        background-repeat: no-repeat;
                        vertical-align: text-top;
                        margin-right: 5px;
                }
                #compare-all :before {
                        background-image: url(/media/wysiwyg/Mathers/compare-icons/Compare1.png);
                        background-size: contain;
                        width: 18px;
                        height: 15px;
                        margin: 0;
                        display: inline-block;
                        content: "";
                        background-repeat: no-repeat;
                        vertical-align: text-top;
                        margin-right: 5px;
                }
        }
}

.navigation-bar__group .compare {
        .compare-counter {
                border: 2px solid #000 !important;
                color: black !important;
                background-color: white;
        }
}

@media (max-width: 960px) {
        .col-product,
        .compare-product-info {
                min-height: 500px !important;
        }
        .selling_feature {
                display: none;
        }
        .site-header__mobile-links {
                .compare .counter {
                        border: 2px solid #000 !important;
                        color: black !important;
                        background-color: white;
                }
        }
}
