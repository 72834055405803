.siteHeaderBannerContent {
  padding: 18px $spacing-unit-base--small;
  line-height: 1em;

  @media (min-width: $breakpoint2) {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  @media (min-width: $breakpoint1) {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

.site-header__banner .site-header__logo-container {
  flex: 0 0 200px;

  @media (min-width: $breakpoint2) {
    flex-basis: 200px;
  }

  @media (min-width: $breakpoint1) {
    flex-basis: 200px;
  }

  .main-logo {
    display: block;
  }

  .scrolled-logo {
    display: none;
  }
}

.navigation-bar__group--search {
  .minisearch__control--text {
    height: 43px;
    /*Width set in media query below*/
    border: 1px solid #000;
    padding: 0 47px 0 20px;
    width: 100%;
  }

  .minisearch__control--submit {
    position: absolute;
    right: 2px;
    top: 9px;
  }
}

.site-header__mobile-top {
  border-bottom: 1px solid #c9c9c9;

  .minisearch {
    background-color: $color-white;
    border-bottom: 1px solid $color-border-outline-light;

    .minisearch__control--text {
      border: 1px solid $color-border-outline;
      background-color: $color-background;
    }

    .search-close {
      top: 5px;
      right: 18px;
    }

    .search-close-button {
      color: $color-border-outline;
    }
  }
}

.site-header__mobile-top__inner {
  background-color: $color-white;

  .site-header__mobile-links {
    li {
      a {

        &:before {
          color: $color-blue;
        }
      }

      &.wishlist > a:before {
        @include icomoon('wishlist');
      }

      &.minicart > a:before {
        @include icomoon('cart');
      }

    }

  }

  .site-header__logo-container {
    .main-logo {
      display: block;
    }

    .scrolled-logo {
      display: none;
    }
  }
}

.navigation-bar__group--account {
  & > li {
    & > a {
      color: $color-blue;
      letter-spacing: $subtitle-letter-spacing;

      &:before {
        font-size: $font-size-icon-navigation-bar;
        color: $color-blue;
      }

      &:hover {
        color: $color-blue;

        &:before {
          color: $color-blue;
        }
      }
    }
  }

  .wishlist > a:before {
    @include icomoon('wishlist');
  }

  .minicart > a:before {
    @include icomoon('cart');
  }
}

.navigation-bar-mobile__toggle {
  &:before {
    color: $color-blue;
  }
}

.navigation-bar-scrolled {
  .navigation-bar__group--account {
    & > li {
      & > a {
        &:before {
          color: $color-blue;
        }

        &:hover {
          &:before {
            color: $color-blue;
          }
        }
      }
    }
  }
}

.site-header__mobile-top .minisearch .minisearch__control--text::placeholder {
  font-size: 14px;
}