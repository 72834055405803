$header-sub-strip-spacing: 0;
$header-sub-strip-spacing-mobile: 0;

.header-sub-strip__inner {
  padding-left: 0;
}

.header-sub-strip__list {
  @media (min-width: $breakpoint4) {
    margin-left: 0;
    margin-right: 0;
  }

  li {
    @media (min-width: $breakpoint4) {
      margin-right: 10px;
      padding-left: 0;
    }

    a {
      line-height: 1;

      @media (min-width: $breakpoint2) {
        white-space: nowrap;
      }

      &:before,
      i {
        color: $color-black;

        @media (min-width: $breakpoint5) {
          font-size: 24px;
          margin-right: 5px;
        }

        @media (min-width: $breakpoint4) {
          font-size: 24px;
          margin-right: 5px;
        }

        @media (min-width: $breakpoint2) {
          font-size: 24px;
          margin-right: 5px;
          line-height: 1;
        }
      }

      @media (min-width: $breakpoint2) {
        font-size: 0.7rem;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
