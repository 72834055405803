.checkout-app__shoppingbag-section {
  order: 3;
}

.checkout-app-tab-section__content {
  padding: 30px;
}


/* Fix for new address popup scroll */
.checkout-index-index .new-address-popup .modal-inner-wrap {
  height: 100% !important;
  overflow-y: auto !important;
}