.cart-fusion-points-wrapper {
  &:before {
    @include icomoon('rewards');
    font-size: 35px;
    margin-bottom: 5px;
    display: inline-block;
  }

  .fusion-point-count {
    font-weight: bold;
  }
}

.minicart {
  .minicart__summary {
    .minicart__count {
      color: $color-blue;
      border: 2px solid $color-blue;
    }
  }

  .minicart__details {
    .block-content {
      .cart.actions {
        .action {
          color: $color-white;
          padding: 12px 10px;
          font-weight: 400;
          font-size: 14px;
          background-color: $color-blue !important;
        }
      }
    }
  }
  .minicart-actions {
    font-weight: bold;
  }

  .subtotal {
    .price-container {
      font-weight: bold;
    }
  }
  .minicart-items {
    .product-item {
      .product-item-details {
        padding-right: 0;

        .minicart-details-header {
          padding-right: 40px;
        }

        .product.options.list {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.minicart-bag-btn .viewcart {
  color: $color-white;
  font-weight: 400;
  font-size: 14px;
}
