#js-aligent_fredhopper-autocomplete-wrapper {
  form { // using element since ootb element doesnt have a class
    border: none;
  }

  #js-aligent_fredhopper-autocomplete-search {
    border: none;
    padding: 10px 120px 10px 20px;
    background-color: #EFEFEE;

    @include placeholder {
      text-transform: none;
      color: $color-input-placeholder;
    }
  }

  .minisearch__control--submit {
    border: none;
    position: relative;
    padding: 5px 30px 5px 40px;
    background: $color-blue;
    overflow: hidden;

    &:hover {
      background: $color-blue;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -10px;
      top: -20px;
      width: 30px;
      height: 100px;
      transform: rotate(-40deg);
      background: $color-background;
    }
  }

}

/* MMI-371 */
@media (max-width: 960px) {
  .site-header__banner .siteHeaderBannerInnerContent > * {
    display: none;
  }
  #js-aligent_fredhopper-autocomplete-results {
    top: -10px;
  }
}
