.site-footer__site-map {
  .site-map__links-column {
    li, a {
      color: $color-white;
    }

    a:hover,
    a:focus {
      color: $color-light-grey;
    }
  }
}
