.site-header__strip {
  .header-strip__inner {
    display: flex;
    justify-content: space-between;

    .header-sub-strip {
      background: none;
    }
  }

  .header-strip__list {
    margin: 0;

    .header-strip-item {
      a {
        &:hover {
          color: $color-darker-grey;
        }

        &:before {
          font-size: 24px;
        }
      }
    }
  }

  .header-strip-item--storefinder {
    flex: 0 auto;
  }

  .user-nav {
    background: $color-white;
  }
}
