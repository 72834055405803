#product-attribute-specs-table tbody tr th {
  text-align: left;
}

.product__stock-message {
  color: $color-red;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
}

.product-info__main .product-info__title,
.product-info__main .product-title__brand {
  text-transform: uppercase;
  font-family: $font-family-base;
}

.product-info__main .add-to-cart__submit {
  color: $color-white;
}

.product-compare-wrapper a:before {
  background-image: url(/media/wysiwyg/Mathers/compare-icons/Compare1.png);
  background-size: contain;
  width: 25px;
  height: 25px;
  margin: 0;
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  vertical-align: text-top;
  margin-right: 5px;
}

/* MMI-1759 PDP Hero image */
.fotorama__wrap .fotorama__stage__frame .fotorama__img {
    max-height: 94.20%;
    max-width: 79.5%;
}

@media (max-width: 400px) {
  [data-bv-show=rating_summary] .bv_main_container {
    top: 18px !important;
  }
}
